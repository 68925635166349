
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonButton, toastController, IonSpinner, IonLabel, IonInput, IonTextarea, IonItem, IonSelect, IonSelectOption, IonGrid, IonToggle, IonCard, IonCardHeader, IonCardSubtitle, IonIcon } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { cloudDoneOutline, cloudDoneSharp, arrowBackOutline, arrowBackSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'InseratEditData',
  data () {
    return {
      doneIconIos: cloudDoneOutline,
      doneIconMd: cloudDoneSharp,
      backIconIos: arrowBackOutline,
      backIconMd: arrowBackSharp,
      finishedLoading: true,
      finishedLoadingCategories: false,
      finishedLoadingDonationRecipients: false,
      finishedUploading: true,
      doneUploading: false,
      errorWhileLoading: false,
      inserat: null,
      pathToInseratEdit: null,
      categories: null,
      totalCategories: 0,
      donationRecipients: null,
      totalRecipients: 0,
      currencyOptions: ["€", "$", "CHF"],
      title: "",
      description: "",
      category: null,
      price: 0,
      currency: "€",
      isDonation: false,
      donationRecipientId: ""
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
    IonSpinner,
    IonLabel,
    IonInput,
    IonTextarea,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonToggle,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonIcon
  },
  created() {
    localStorage.addedImageToInserat = false;

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' });
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' });
    }

    // check if route param is available
    if (!this.$route.params.id){
      this.$router.push({ path: '/profile/inserats' });
    }

    // get list of donation recipients
    axios.get(process.env.VUE_APP_API_URL + '/donation_recipients')
        .then(response => {
          this.donationRecipients = response.data["hydra:member"];
          this.totalRecipients = response.data["hydra:totalItems"];
        })
        .finally(() => {
          this.finishedLoadingDonationRecipients = true;
          if (this.totalRecipients == 0){
            this.openToast("Etwas ist schiefgelaufen. Probiere es später nochmal.", 10000);
            this.errorWhileLoading = true;
          }
        });

    // get categories
    axios.get(process.env.VUE_APP_API_URL + '/categories')
        .then(response => {
          this.categories = response.data["hydra:member"];
          this.totalCategories = response.data["hydra:totalItems"];
        })
        .finally(() => {
          this.finishedLoadingCategories = true;
          if (this.totalCategories == 0){
            this.openToast("Etwas ist schiefgelaufen. Probiere es später nochmal.", 10000);
            this.errorWhileLoading = true;
          }
        });

    // get inserat data
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$route.params.id, {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
        .then(response => {

          this.inserat = response.data;

          if (this.inserat.isActive){
              this.openToast("Dein Inserat muss offline sein, damit du es bearbeiten kannst.", 4000);
              this.errorWhileLoading = true;
          }

          this.pathToInseratEdit = "/inserat/" + this.inserat.uuid + "/edit";
          this.title = this.inserat.title;
          this.description = this.inserat.description;
          this.category = this.inserat.category.replace('/categories/','');
          this.price = this.inserat.price;
          this.currency = this.inserat.currency;
          this.isDonation = this.inserat.isDonation;
          if (this.isDonation){
            this.donationRecipientId = this.inserat.donationRecipient.replace('/donation_recipients/','');
          }

        })
        .catch(error => {
          this.errorWhileLoading = true;
          console.log(error);
        })
        .finally(() => {
          this.finishedLoading = true;
        });


  },
  methods: {
    getRoute() {
      return "/inserat/" + this.$route.params.id + "/edit";
    },
    update(){

      if (!this.title || !this.description || !this.category || !this.price || !this.currency) {
          this.openToast("Überprüfe deine Eingaben.", 2500);
          return;
      }

      if (this.isDonation && !this.donationRecipientId) {
        this.openToast("Überprüfe den Spendenempfänger.", 2500);
        return;
      }

      const inseratData = {
        'title': this.title,
        'description': this.description,
        'category': '/categories/' + this.category,
        'price': parseInt(this.price),
        'currency': this.currency,
        'isDonation': this.isDonation
      };

      if (this.isDonation){
        inseratData['donationRecipient'] = '/donation_recipients/' + this.donationRecipientId;
      }

      // patch inserat updates
      this.finishedUploading = false;
      axios.patch(process.env.VUE_APP_API_URL + '/inserats/' + this.inserat.uuid, inseratData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken,
          'Content-Type' : 'application/merge-patch+json',
        }
      })
          .then(response => {
            console.log(response);
            this.doneUploading = true;
          })
          .catch(error => {
            this.errorWhileCreating = true;
          }).finally(() => {
            this.finishedUploading = true;
            //this.$router.push({ path: this.pathToInseratEdit});
      });

    },
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    }
  }
});

