<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Inserat Daten bearbeiten</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="finishedLoading && finishedLoadingCategories && finishedLoadingDonationRecipients && finishedUploading && !doneUploading">
      <ion-row class="sideMargins topMargins">
        <ion-col size="12">
          <ion-button size="small" style="margin-left: 16px; margin-bottom: 25px;" :router-link="getRoute()"><ion-icon :ios="backIconIos" :md="backIconMd"></ion-icon> Abbrechen</ion-button>
        </ion-col>
        <ion-col size="12" v-if="inserat && inserat.isSubmitted === true" style="margin-top: -40px;">
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle>Hinweis</ion-card-subtitle>
              <small>Wenn du änderungen an deinem Inserat vornimmst, musst du es danach nochmal zur Prüfung abschicken.</small>
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked">Title</ion-label>
            <ion-input v-model="title"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked">Beschreibung</ion-label>
            <ion-textarea autoGrow="true" v-model="description" maxlength="250" style="max-height: 220px;"></ion-textarea>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked">Kategorie</ion-label>
            <ion-select placeholder="Wähle eine Kategorie" v-model="category">
              <ion-select-option v-for="category in categories" :key="category.uuid" :value="category.uuid">{{ category.name }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-label position="stacked" v-if="inserat && inserat.inseratType === 'directbuy'">Preis</ion-label>
            <ion-label position="stacked" v-if="inserat && inserat.inseratType === 'auction'">Startpreis</ion-label>
            <ion-input v-model="price"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-label position="stacked">Währung</ion-label>
            <ion-select placeholder="auswählen" v-model="currency">
              <ion-select-option v-for="c in currencyOptions" :key="c" :value="c">{{ c }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-grid>
              <ion-row>
                <ion-col size="3">
                  <ion-toggle v-model="isDonation" style="margin-bottom: 10px;"></ion-toggle>
                </ion-col>
                <ion-col size="9">
                  <ion-label class="ion-text-wrap">Soll der Erlös gespendet werden?</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-col>
        <ion-col size="12" v-if="isDonation">
          <ion-item>
            <ion-label position="stacked">Spendenempfänger</ion-label>
            <ion-select placeholder="auswählen" v-model="donationRecipientId">
              <ion-select-option v-for="d in donationRecipients" :key="d.id" :value="d.uuid">{{ d.name }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-button color="danger" style="width: 100%; margin-top: 25px; margin-bottom: 25px;" @click="update">Speichern</ion-button>
        </ion-col>
      </ion-row>
    </ion-content>

    <ion-content v-if="!finishedLoading || !finishedLoadingCategories || !finishedLoadingDonationRecipients || !finishedUploading" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

    <ion-content v-if="doneUploading" :fullscreen="true">
      <div class="container sideMargins">
        <ion-icon :ios="doneIconIos" :md="doneIconMd" style="width: 100px; height: 100px;"></ion-icon><br><br><br>
        <strong class="capitalize">Deine Änderungen wurden gespeichert</strong>
        <p>Bitte schicke dein Inserat nun erneut zur Prüfung</p>
        <ion-button size="large" class="topMargins sideMargins" :href="pathToInseratEdit" expand="block" color="primary">Fertig</ion-button>
      </div>
    </ion-content>

    <ion-content :fullscreen="true" v-if="errorWhileLoading">
      <div class="container">
        <strong class="capitalize">Fehler</strong>
        <p>Versuch es noch einmal in ein paar Minuten.</p>
        <ion-button size="large" class="topMargins sideMargins" :href="pathToInseratEdit" expand="block" color="primary">Später versuchen</ion-button>
      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonButton, toastController, IonSpinner, IonLabel, IonInput, IonTextarea, IonItem, IonSelect, IonSelectOption, IonGrid, IonToggle, IonCard, IonCardHeader, IonCardSubtitle, IonIcon } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { cloudDoneOutline, cloudDoneSharp, arrowBackOutline, arrowBackSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'InseratEditData',
  data () {
    return {
      doneIconIos: cloudDoneOutline,
      doneIconMd: cloudDoneSharp,
      backIconIos: arrowBackOutline,
      backIconMd: arrowBackSharp,
      finishedLoading: true,
      finishedLoadingCategories: false,
      finishedLoadingDonationRecipients: false,
      finishedUploading: true,
      doneUploading: false,
      errorWhileLoading: false,
      inserat: null,
      pathToInseratEdit: null,
      categories: null,
      totalCategories: 0,
      donationRecipients: null,
      totalRecipients: 0,
      currencyOptions: ["€", "$", "CHF"],
      title: "",
      description: "",
      category: null,
      price: 0,
      currency: "€",
      isDonation: false,
      donationRecipientId: ""
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
    IonSpinner,
    IonLabel,
    IonInput,
    IonTextarea,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonToggle,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonIcon
  },
  created() {
    localStorage.addedImageToInserat = false;

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
      // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' });
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' });
    }

    // check if route param is available
    if (!this.$route.params.id){
      this.$router.push({ path: '/profile/inserats' });
    }

    // get list of donation recipients
    axios.get(process.env.VUE_APP_API_URL + '/donation_recipients')
        .then(response => {
          this.donationRecipients = response.data["hydra:member"];
          this.totalRecipients = response.data["hydra:totalItems"];
        })
        .finally(() => {
          this.finishedLoadingDonationRecipients = true;
          if (this.totalRecipients == 0){
            this.openToast("Etwas ist schiefgelaufen. Probiere es später nochmal.", 10000);
            this.errorWhileLoading = true;
          }
        });

    // get categories
    axios.get(process.env.VUE_APP_API_URL + '/categories')
        .then(response => {
          this.categories = response.data["hydra:member"];
          this.totalCategories = response.data["hydra:totalItems"];
        })
        .finally(() => {
          this.finishedLoadingCategories = true;
          if (this.totalCategories == 0){
            this.openToast("Etwas ist schiefgelaufen. Probiere es später nochmal.", 10000);
            this.errorWhileLoading = true;
          }
        });

    // get inserat data
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$route.params.id, {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken
      }
    })
        .then(response => {

          this.inserat = response.data;

          if (this.inserat.isActive){
              this.openToast("Dein Inserat muss offline sein, damit du es bearbeiten kannst.", 4000);
              this.errorWhileLoading = true;
          }

          this.pathToInseratEdit = "/inserat/" + this.inserat.uuid + "/edit";
          this.title = this.inserat.title;
          this.description = this.inserat.description;
          this.category = this.inserat.category.replace('/categories/','');
          this.price = this.inserat.price;
          this.currency = this.inserat.currency;
          this.isDonation = this.inserat.isDonation;
          if (this.isDonation){
            this.donationRecipientId = this.inserat.donationRecipient.replace('/donation_recipients/','');
          }

        })
        .catch(error => {
          this.errorWhileLoading = true;
          console.log(error);
        })
        .finally(() => {
          this.finishedLoading = true;
        });


  },
  methods: {
    getRoute() {
      return "/inserat/" + this.$route.params.id + "/edit";
    },
    update(){

      if (!this.title || !this.description || !this.category || !this.price || !this.currency) {
          this.openToast("Überprüfe deine Eingaben.", 2500);
          return;
      }

      if (this.isDonation && !this.donationRecipientId) {
        this.openToast("Überprüfe den Spendenempfänger.", 2500);
        return;
      }

      const inseratData = {
        'title': this.title,
        'description': this.description,
        'category': '/categories/' + this.category,
        'price': parseInt(this.price),
        'currency': this.currency,
        'isDonation': this.isDonation
      };

      if (this.isDonation){
        inseratData['donationRecipient'] = '/donation_recipients/' + this.donationRecipientId;
      }

      // patch inserat updates
      this.finishedUploading = false;
      axios.patch(process.env.VUE_APP_API_URL + '/inserats/' + this.inserat.uuid, inseratData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken,
          'Content-Type' : 'application/merge-patch+json',
        }
      })
          .then(response => {
            console.log(response);
            this.doneUploading = true;
          })
          .catch(error => {
            this.errorWhileCreating = true;
          }).finally(() => {
            this.finishedUploading = true;
            //this.$router.push({ path: this.pathToInseratEdit});
      });

    },
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    }
  }
});

</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 10%;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.imageCircleDenied {
  border: 2px solid #c61d53;
}

.imageCircleAccepted {
  border: 2px solid #1dc620;
}

.rounded-image {
  border-radius: 50%;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-btn-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  width: 100%;
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

</style>